import { apiUrl, testInf, readyEvent } from "./api";
import fetchUrl from "./fetch";

let cuid = localStorage.getItem("cuid") === null ? "" : localStorage.getItem("cuid");
const chatInitBody = { uuid: testInf, cuid: cuid };

const chatInit = async () => {
  try {
    const request = await fetchUrl(apiUrl, "init", chatInitBody);
    const content = await request.json();
    cuid = content.result.cuid;
    localStorage.setItem("cuid", cuid);
  } catch (error) {
    console.log(error);
  }
};

const chatEvent = async () => {
  try {
    const chatRequestBody = { cuid: cuid, euid: readyEvent };

    const request = await fetchUrl(apiUrl, "event", chatRequestBody);
    const content = await request.json();
    return content.result.text.value;
  } catch (error) {
    console.log(error);
  }
};

const chatRequest = async text => {
  try {
    const chatRequestBody = { cuid: cuid, text: text };

    const request = await fetchUrl(apiUrl, "request", chatRequestBody);
    const content = await request.json();
    return content.result.text.value;
  } catch (error) {
    console.log(error);
  }
};

export { chatInit, chatRequest, chatEvent };
