import React, { PureComponent } from "react";
import style from "./SocialShare.module.scss";
import classnames from "classnames";
/* import image from "../../assets/images/encodeman_Logo_encodeman.png"; */

class SocialShare extends PureComponent {
  render() {
    return (
      <div
        className={classnames("social-share__container ya-share2", style.socialGroup)}
        data-services="vkontakte,twitter,facebook,gplus"
        data-description="Виртуальный Encodeman – это спецпроект компании Наносемантика. Мы 14 лет разрабатываем чат-ботов, виртуальных собеседников и другие системы искусственного интеллекта."
        data-image="https://encodeman.ru/assets/images/encodeman_Logo_encodeman.png"
        data-title="Виртуальный Encodeman"
        data-url="https://encodeman.ru/"
        data-counter
      />
    );
  }
}

export default SocialShare;
