import React, { PureComponent } from "react";
import style from "../Frontpage.module.scss";
import WidgetComponent from "../../../components/Widget/Widget";
import SocialShare from "../../../components/SocialShare/SocialShare";

class Body extends PureComponent {
  render() {
    return (
      <div className={style.body}>
        <WidgetComponent />
        <SocialShare />
      </div>
    );
  }
}

export default Body;
