import React, { PureComponent } from "react";
import style from "./Frontpage.module.scss";
import Header from "./Components/Header";
import Body from "./Components/Body";
import Footer from "./Components/Footer";
import MediaQuery from "react-responsive";

class Frontpage extends PureComponent {
  render() {
    return (
      <div className={style.pageContainer}>
        <Header />
        <Body />
        <Footer />
        <MediaQuery minWidth={767}>
          <div className={style.bg} />
        </MediaQuery>
      </div>
    );
  }
}

export default Frontpage;
