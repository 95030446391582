import React, { PureComponent } from "react";
import style from "./DefaultLayout.scss";
import Frontpage from "../pages/frontpage/Frontpage";

class DefaultLayout extends PureComponent {
  render() {
    return <Frontpage />;
  }
}

export default DefaultLayout;
