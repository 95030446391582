import React, { PureComponent } from "react";
import style from "../Frontpage.module.scss";
import imhHeader from "../../../assets/images/encodeman_Logo_encodeman.png";

class Header extends PureComponent {
  render() {
    return (
      <div className={style.header}>
        {/*<h1>Энкодмен и его энкоды</h1> */}
        <img src={imhHeader} alt="Энкодмен и его энкоды" />
      </div>
    );
  }
}

export default Header;
