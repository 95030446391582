/** REACT */
import React from "react";
import ReactDOM from "react-dom";

/** APP */
import App from "./App";
/** STYLES */
import "Styles/index.scss";
/** REACT APP */

ReactDOM.render(<App />, document.getElementById("js__react-container"));

/** WEBPACK HOTLOADER */

module.hot.accept();
