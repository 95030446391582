import React, { Component } from "react";

/** WIDGET */
import { Widget, addResponseMessage, addUserMessage, dropMessages } from "chat-widget-react";
import { chatInit, chatRequest, chatEvent } from "../../widget/requests";

import logo from "Assets/images/encodeman-chat-bot.jpg";

/** LOCAL STORAGE */
const savedMessages = JSON.parse(localStorage.getItem("saved_messages")); // CHANGE LATER

class WidgetComponent extends Component {
  componentDidMount() {
    if (savedMessages) {
      savedMessages.forEach(msg => {
        msg.sender === "response" ? addResponseMessage(msg.text) : addUserMessage(msg.text);
      });

      return chatInit();
    }
    const flow = async () => {
      await chatInit();
      const data = await chatEvent();
      return data;
    };

    flow().then(data => addResponseMessage(data));
  }

  handleNewUserMessage = newMessage => {
    const request = chatRequest(newMessage);
    request.then(data => addResponseMessage(data));
  };

  resetFunc = () => {
    const flow = async () => {
      await chatInit();
      const data = await chatRequest("Привет");
      return data;
    };

    flow().then(data => addResponseMessage(data));
    dropMessages();
  };

  render() {
    return (
      <React.Fragment>
        <Widget
          title="Привет"
          subtitle="Encodeman.ru"
          senderPlaceHolder="Напишите вопрос ..."
          profileAvatar={logo}
          handleNewUserMessage={this.handleNewUserMessage}
          /* badge={1} */
          openIcon={logo}
          resetFunc={this.resetFunc}
          showTextLauncher={true}
          textLauncherText="Давай поговорим о жизни?<br/>Кликни меня"
        />
      </React.Fragment>
    );
  }
}

export default WidgetComponent;
