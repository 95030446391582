import React, { PureComponent } from "react";
import style from "../Frontpage.module.scss";

import logo from "Assets/images/nslogo.png";

class Footer extends PureComponent {
  render() {
    return (
      <div className={style.footer}>
        <a
          className={style.logo}
          href="https://nanosemantics.ai/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={logo} />
        </a>
        <p className={style.text}>
          <span>
            Виртуальный Encodeman – это спецпроект компании{" "}
            <a
              href="https://nanosemantics.ai/?utm_source=web&utm_medium=sites&utm_campaign=encodeman"
              rel="noopener noreferrer"
              target="_blank"
            >
              Наносемантика
            </a>
            . Мы 14 лет разрабатываем чат-ботов, виртуальных собеседников и другие системы
            искусственного интеллекта. Чат-бот создан Наносемантикой на основе-фраз энкодов из новой
            книги Алексея Ходорыча и Вадима Петровского{" "}
            <a href="http://encody.ru/" rel="noopener noreferrer" target="_blank">
              "Идеальный аргумент"
            </a>
            . Если вам нужен консультант, FAQ или промоутер в виде чат-бота на сайте или в
            мессенджере –{" "}
            <a
              href="https://nanosemantics.ai/?utm_source=web&utm_medium=sites&utm_campaign=encodeman"
              rel="noopener noreferrer"
              target="_blank"
            >
              обращайтесь к нам
            </a>
            .
          </span>
        </p>
      </div>
    );
  }
}

export default Footer;
