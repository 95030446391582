import React, { Component } from "react";

/** REACT COMPONENTS */
import DefaultLayout from "./layouts/DefaultLayout";

class App extends Component {
  render() {
    return (
      <div className="App">
        <DefaultLayout />
      </div>
    );
  }
}

export default App;
